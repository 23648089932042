import React from "react";
import {
  Ionicons,
  AntDesign,
  MaterialIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { View, Text, TouchableOpacity } from "react-native";
import tw from "twrnc";

const iconos = {
  pdf: <AntDesign name="pdffile1" size={24} color="red" />,
  png: <MaterialIcons name="image" size={24} color="blue" />,
  jpg: <MaterialIcons name="image" size={24} color="blue" />,
  jpeg: <MaterialIcons name="image" size={24} color="blue" />,
  "vnd.openxmlformats-officedocument.wordprocessingml.document": (
    <AntDesign name="wordfile1" size={24} color="red" />
  ),
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <MaterialCommunityIcons name="microsoft-excel" size={24} color="green" />
  ),
};

const ArchivoItem = ({ index, file, handleFileRemove }) => {
  const extension = file?.type?.split("/").pop();
  const isDeleteable = handleFileRemove ? true : false;
  return (
    <View
      style={tw`bg-white p-2 my-2 flex flex-row items-center gap-2 w-full max-w-[300px] border border-gray-200 rounded-md`}
    >
      <View style={tw`border border-gray-200 rounded-md p-1`}>
        {extensionFile ? iconos[extensionFile] : null}
      </View>
      <Text ellipsizeMode="tail" numberOfLines={1} style={tw`flex-1`}>{file.name}</Text>
      {isDeleteable ? (
        <TouchableOpacity onPress={() => handleFileRemove(index)}>
          <Ionicons name="trash-outline" size={24} color="red" />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default ArchivoItem;
