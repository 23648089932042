import { useEffect, useState } from "react";
import { Text } from "react-native";
import { useQueryClient } from "react-query";
import tw from "twrnc"

const TipoItemSeleccionado = ({tipoId}) => {
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData("TiposItem")
    const [tipoSeleccionado, setTipoSeleccionado] = useState("Tipo no definido")
    useEffect(() => {
      if (data) {
        const [tipo] = data.data?.tipos?.filter( tipo => tipo.id == tipoId)
        if (tipo) {
          console.log(tipo)
          setTipoSeleccionado(tipo.tipo)
        }
      }
    }, [data])
    return <Text style={tw`text-lg font-semibold text-gray-500 my-4`}>{tipoSeleccionado}</Text>
  
  }

export default TipoItemSeleccionado