import React, { useEffect } from "react";
import { Text, View, TextInput } from "react-native";
import tw from "twrnc";
import TiposItemSelect from "./TiposItem";
import CustomCheckbox from "../CustomCheckbox";
import WebDatePicker from "../WebDatePicker";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import ContainerStep from "./ContainerStep";

const ContentStep1 = ({ item, formData, currentStep }) => {
  const ctx = useFormContext();
  const {
    control,
    watch,
    formState: { errors },
  } = ctx;

  const caduca = watch("caduca");
  const fechaCaduca = watch("fechaCaduca");
  const startDate = (new Date());
  
  if (fechaCaduca) {
    formData.delete("fechaCaduca");
    const fecha = moment(startDate).format("YYYY/MM/DD");
    formData.append("fechaCaduca", fecha);
  }

  return (
    <ContainerStep step={1} currentStep={currentStep}>
      <View style={tw`flex flex-row items-start gap-4`}>
        <View style={tw`max-w-[350px] border border-gray-200 rounded-md p-4`}>
          <Text style={tw`font-bold mt-4`}>Título</Text>
          <Controller
            control={control}
            rules={{
              required: "Ingrese un titulo",
              minLength: {
                value: 2,
                message: "Al menos 3 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  style={tw` mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid ${
                    errors?.titulo ? "border-red-700" : "border-gray-300"
                  } rounded`}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="ej. Casco"
                  value={value}
                />
                {errors?.titulo && (
                  <Text style={tw`text-red-700`}>
                    Error: {errors.titulo?.message}
                  </Text>
                )}
              </>
            )}
            name="titulo"
            defaultValue=""
          />
          <Text style={tw`font-bold mb-2 mt-2`}>Tipo de item:</Text>
          <Controller
            control={control}
            rules={{
              required: "Indique un tipo",
              valueAsNumber: {
                value: true,
                message: "Indique un tipo",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TiposItemSelect
                  tipoItemSeleccionado={value}
                  seleccionar={onChange}
                />
                {errors?.tipo_id && (
                  <Text style={tw`text-red-700`}>
                    Error: {errors.tipo_id?.message}
                  </Text>
                )}
              </>
            )}
            name="tipo_id"
            defaultValue=""
          />

          <Text style={tw`font-bold mt-4`}>Cantidad (opcional)</Text>
          <Controller
            control={control}
            rules={{
              min: {
                value: 1,
                message: "No puede ser menor a 1",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  keyboardType="number-pad"
                  style={tw` mt-3 w-[100px] px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid ${
                    errors?.cantidadEntregada
                      ? "border-red-700"
                      : "border-gray-300"
                  } rounded`}
                  onBlur={onBlur}
                  onChangeText={(value) =>
                    onChange(value.replace(/[^0-9]/g, ""))
                  }
                  placeholder="1"
                  value={value}
                />
                {errors?.cantidadEntregada && (
                  <Text style={tw`text-red-700`}>
                    Error: {errors.cantidadEntregada?.message}
                  </Text>
                )}
              </>
            )}
            name="cantidadEntregada"
            defaultValue="1"
          />

          <View style={tw`flex flex-col items-start justify-between`}>
            <Controller
              control={control}
              rules={{
                valueAsNumber: {
                  value: true,
                  message: "Indique un 0 o 1",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <CustomCheckbox
                    label="Requiere Conformidad Devolución"
                    value={value}
                    setValue={onChange}
                  />
                  {errors?.requiereConformidadDevolucion && (
                    <Text style={tw`text-red-700`}>
                      Error: {errors.requiereConformidadDevolucion?.message}
                    </Text>
                  )}
                </>
              )}
              name="requiereConformidadDevolucion"
            />

            <Controller
              control={control}
              rules={{
                required: "Indique un tipo",
                valueAsNumber: {
                  value: true,
                  message: "Indique un tipo",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <CustomCheckbox
                    label="Requiere Conformidad Recepción"
                    value={value}
                    setValue={onChange}
                  />
                  {errors?.requiereConformidadRecepcion && (
                    <Text style={tw`text-red-700`}>
                      Error: {errors.requiereConformidadRecepcion?.message}
                    </Text>
                  )}
                </>
              )}
              name="requiereConformidadRecepcion"
            />

            {item ? (
              <Controller
                control={control}
                rules={{
                  valueAsNumber: {
                    value: true,
                    message: "Indique un tipo",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <CustomCheckbox
                      label="Conforme Devolución"
                      value={value}
                      setValue={onChange}
                    />
                    {errors?.conformeDevolucion && (
                      <Text style={tw`text-red-700`}>
                        Error: {errors.conformeDevolucion?.message}
                      </Text>
                    )}
                  </>
                )}
                name="conformeDevolucion"
              />
            ) : null}

            {item ? (
              <Controller
                control={control}
                rules={{
                  valueAsNumber: {
                    value: true,
                    message: "Indique un tipo",
                  },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <CustomCheckbox
                      label="Conforme Recepción"
                      value={value}
                      setValue={onChange}
                    />
                    {errors?.conformeRecepcion && (
                      <Text style={tw`text-red-700`}>
                        Error: {errors.conformeRecepcion?.message}
                      </Text>
                    )}
                  </>
                )}
                name="conformeRecepcion"
              />
            ) : null}

            <Controller
              control={control}
              rules={{
                valueAsNumber: {
                  value: true,
                  message: "Indique un tipo",
                },
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <CustomCheckbox
                    label="Caduca"
                    value={value}
                    setValue={onChange}
                  />
                  {errors?.caduca && (
                    <Text style={tw`text-red-700`}>
                      Error: {errors.caduca?.message}
                    </Text>
                  )}
                </>
              )}
              name="caduca"
            />

            {caduca ? (
              <View style={tw`flex flex-col items-start mt-4 pl-4 gap-2`}>
                <View>
                  <Text style={tw`text-gray-600`}>
                    Indique la fecha de caducidad
                  </Text>
                </View>
                <View>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <WebDatePicker
                          selected={value}
                          minDate={new Date()}
                          onChange={onChange}
                        />
                        {errors?.fechaCaduca && (
                          <Text style={tw`text-red-700`}>
                            Error: {errors.fechaCaduca?.message}
                          </Text>
                        )}
                      </>
                    )}
                    name="fechaCaduca"
                  />
                </View>
              </View>
            ) : null}
          </View>
        </View>

        <View style={tw`flex-1 border border-gray-200 rounded-md p-4`}>
          <Text style={tw`font-bold mt-4`}>Descripcion (opcional)</Text>
          <Controller
            control={control}
            rules={{
              minLength: {
                value: 2,
                message: "Al menos 3 caracteres",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <TextInput
                  style={tw`min-h-[100px] mt-3 w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded`}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  placeholder="Descripción"
                  value={value}
                  multiline={true}
                />
                {errors?.descripcion && (
                  <Text style={tw`text-red-700`}>
                    Error: {errors.descripcion?.message}
                  </Text>
                )}
              </>
            )}
            name="descripcion"
            defaultValue=""
          />
        </View>
      </View>
    </ContainerStep>
  );
};

export default ContentStep1;
